@import 'app.scss';
.wrapper {
  grid-column: var(--section-width, 1 / -1);
  margin-bottom: 0;
  border-radius: var(--border-radius-base);
  background-color: var(--color-bg-default);
  padding: spacing(4) spacing(6) spacing(6);

  @include from-breakpoint(md) {
    padding: spacing(4) spacing(6) spacing(6);
  }
}

.wrapper.altBackground {
  background-color: var(--color-bg-alternative);
}

.iconContainer {
  margin-right: spacing(3);
}

.svgIcon {
  width: 1.5rem;
  height: 1.5rem;
  color: var(--color-red-500);
}
