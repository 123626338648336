@import 'app.scss';
.faqItem {
  border-bottom: 1px solid var(--color-border-light);
}

.header {
  width: 100%;
}

.text {
  margin-left: 0;
}

.accordionButton {
  @include typography-paragraph-md;

  display: flex;
  gap: spacing(1);
  align-items: center;
  justify-content: space-between;
  background-color: var(--bg-color-default);
  padding: spacing(2) 0;
  width: 100%;
  min-height: 50px;
  text-align: left;
  color: var(--color-fg-default);
  font-weight: 500;

  &:focus-visible {
    @include generic-focus;

    outline-offset: spacing(1);
  }

  &:hover {
    text-decoration: underline;
  }

  &[aria-expanded='true'] {
    border-bottom: none;
  }
}

.svgIcon {
  width: 1.25rem;
  height: 1.25rem;
  color: var(--color-red-500);

  .accordionButton:hover & {
    color: var(--color-black-500);
  }
}

.richText {
  padding: spacing(2) 0 spacing(6);
}

.textWrapper {
  transition: var(--transition-duration-collapse);
  overflow: hidden;
}

.fadeEnter {
  height: 0;
}

.fadeEnterActive {
  height: var(--faq-height);
}

.fadeExit {
  height: var(--faq-height);
}

.fadeExitActive {
  height: 0;
}
